/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedRunway as setSelectedRunwayAction,
  setMap
} from "../redux/slice";
import { ReactComponent as Extlnk } from "../data/external-link.svg";
import { ReactComponent as Locate } from "../data/locate.svg";
import "./styles/Sidebar.css";
import "./styles/Airport_detail.css";
import { Helmet } from "react-helmet";
import { FlagIcon } from "react-flag-kit";
import { Oval } from "react-loader-spinner";

function AirportDetail( {airport}) {
  const { selectedRunway, aircraftCards } = useSelector(
    (state) => state.slice
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [isAcsExpanded, setAcsIsExpanded] = useState(true);

  const addAircraftToURL = (aircraftUrlCode) => {
    const currentAcs = aircraftCards?.map((aircraft) => aircraft.url_code) // Get the existing aircraft codes - this is an array of objects
    const isAircraftExist = currentAcs.includes(aircraftUrlCode);
    const basePath = window.location.pathname.split("/").slice(0, 3).join("/");
    
    if (!isAircraftExist) {    
      const updatedAcs = [...currentAcs, aircraftUrlCode];  
      const newPath = updatedAcs.join("&"); // Join the url_codes with "&" separator
      history.push(`${basePath}/${newPath}`);
      }
    else if (isAircraftExist) {
      const updatedAircraftCards = aircraftCards.filter((aircraft) => aircraft.url_code !== aircraftUrlCode );
      const updatedAcs = updatedAircraftCards.map((aircraft) => aircraft.url_code);
      const newPath = updatedAcs.join("&");
      history.push(`${basePath}/${newPath}`);
    }
  };

  const [aircraftList, setAircraftList] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(true);
  const hideSidebar = () => setSidebar(false);

  const [loadingAcList, setloadingAcList] = useState(false);
  const [oat, setOat] = useState ()

  const setSelectedRunway = (payload) => {
    if (payload !== selectedRunway) {
      dispatch(setSelectedRunwayAction(payload));
    }
  };

  // get highlighted runway from state
  useEffect(() => {
    if (selectedRunway) setSelectedRunway(selectedRunway);
  }, [selectedRunway]);

  useEffect(() => {
    const fetchItem = async () => {
      if (!airport) {
        hideSidebar();
      }
      if (airport) {
        // const fetchItem = await fetch(`/api/airport/${airport.icao}`);
        // const item = await fetchItem.json();
        // setItem(item);
          showSidebar();
      }
    };
    fetchItem();
    if (airport) {
    try { getAirportOAT(oat)}
    catch (error) {
      console.log('no wc data');
    }
    }
  }, [airport]);

  const getAirportCompatibility = async () => {
    setloadingAcList(true);
    try {
      const fetchAcs = await fetch(`/api/airport-compatibility/${airport.icao}`);
      const acs = await fetchAcs.json();
      setAircraftList(acs);
    } catch (error) {
      console.error("Error fetching airport compatibility:", error);
    } finally {
      setloadingAcList(false);
    }
  };

  useEffect(() => {
    getAirportCompatibility();
  }, [airport]);

  const locate = () => {
    dispatch(
      setMap({
        longitude: airport.longitude,
        latitude: airport.latitude,
        zoom: 12,
      }))
  };


  const token = 'E99am9UQEmgdgrHgHXF6NyZcnYp8v03H-Te8ZqXlRQA'

  const getAirportOAT= async () => {
    const fetchOat = await fetch(
      `https://avwx.rest/api/metar/${airport.icao}`, {
        headers: {
          Authorization: `BEARER ${token}`
      }
    })    
  const oat = await fetchOat.json();
    setOat(oat.temperature.value)
    };

  const toggleList = () => {
    setAcsIsExpanded(!isAcsExpanded);
  }


  function showAirport() {
    return (
      <div className={sidebar ? "sidebar active" : "sidebar"}>
        <Helmet>
          <title>
            {airport.icao} | {airport.airport_name} - Airport data | Avionia
          </title>
          <meta
            name="description"
            content={
              airport.airport_name +
              " | " +
              airport.icao +
              " Airport data:" +
              " Runway length: " +
              airport.max_runway_length_m +
              " m; Airport elevation: " +
              airport.amsl_m +
              " m." +
              " Detailed" +
              (airport.alt_airport_name ? " " + airport.alt_airport_name : "") +
              (airport.iata ? " | " + airport.iata : "") +
              (airport.city ? " | " + airport.city : "") +
              " Runway data, runway length, width, elevation, number of runways and airport diagram"
            }
          />
          <meta
            name="keywords"
            content={
              airport.icao +
              ", " +
              airport.airport_name +
              (airport.iata ? ", " + airport.iata : "") +
              (airport.city ? ", " + airport.city : "") +
              (airport.alt_airport_name ? ", " + airport.alt_airport_name : "") +
              "runway data, runway length, width, elevation, number of runways, diagram"
            }
          />
          <link
            rel="canonical"
            href={"https://www.avionia.com" + window.location.pathname}
          />
        </Helmet>
        <div className="card-header-a header-ap">
          <div
            className="close"
            onClick={() => {
              history.push("/")
            }}
          ></div>
          <h1
            data-toggle="tooltip"
            title="Airport name"
            onClick={() => locate()}
          >
            {airport.airport_name}
          </h1>
          <div data-toggle="tooltip" title="Alternate airport name">
            {airport.alt_airport_name}
          </div>
          <h2 className="card-same-row">
            <div data-toggle="tooltip" title="ICAO code">
              {airport.icao}
            </div>
            <div>{airport.iata ? "|" : ""}</div>
            <div data-toggle="tooltip" title="IATA code">
              {airport.iata ? airport.iata : ""}
            </div>
          </h2>
        </div>
        <div className="card-country-coordinates">
          <div className="card-country">
            {airport.country ? (
              <FlagIcon code={airport.country && airport.country.code_2} size={16} />
            ) : (
              ""
            )}
            <div data-toggle="tooltip" title="City">
              {airport.city ? airport.city : ""}
            </div>
            <div>{airport.city ? "|" : ""}</div>
            <div data-toggle="tooltip" title="Country">
              {airport.country && airport.country.country_name}
            </div>
          </div>
          <div className="card-coordinates">
            <div data-toggle="tooltip" title="Latitude">
              {airport.latitude}
            </div>{" "}
            |
            <div data-toggle="tooltip" title="Longitude">
              {airport.longitude}
            </div>
            <Locate onClick={() => locate()} className="locate"></Locate>
          </div>
        </div>
        {airport.wiki || airport.url ? (
          <div className="links">
            {airport.wiki ? (
              <a href={airport.wiki}>
                {" "}
                Wiki <Extlnk className="extlnk"></Extlnk>
              </a>
            ) : (
              ""
            )}
            {airport.url ? (
              <a href={airport.url}>
                {" "}
                Website <Extlnk className="extlnk"></Extlnk>
              </a>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <div className="card-amsl-oat">
          <div data-toggle="tooltip" title="Airport elevation">
            AMSL: {airport.amsl_m} m |
            OAT: {oat} °C
          </div>
        </div>
        <h2 className="runway-data-divider">Runway data</h2>
        <div className="card-runway-list">
          <table>
            <tbody>
              <tr>
                <th>Runway</th>
                <th>Length</th>
                <th>Width</th>
              </tr>
              {airport.runways ? (
                [...airport.runways]
                      .sort((a, b) =>
                      a.runway_name.localeCompare(b.runway_name) ||
                      a.runway_width_m - b.runway_width_m ||
                      b.runway_length_m - a.runway_length_m
                    )
                    .map((runway) => {
                    return (
                      <tr
                        className={
                          runway.runway_id == selectedRunway
                            ? "runway-list-item-hovered"
                            : "runway-list-item"
                        }
                        key={runway.runway_id}
                        onMouseEnter={() => {
                          setSelectedRunway(runway.runway_id);
                        }}
                        onMouseLeave={() => {
                          setSelectedRunway("");
                        }}
                      >
                        <td data-toggle="tooltip" title="Runway name">
                          <span className="runway-name">
                            {runway.runway_name}
                          </span>
                        </td>
                        <td data-toggle="tooltip" title="Runway length">
                          {runway.runway_length_m} m
                        </td>
                        <td data-toggle="tooltip" title="Runway width">
                          {runway.runway_width_m} m
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <div>Loading...</div>
              )}
            </tbody>
          </table>
        </div>
        <h2 className={isAcsExpanded ? "runway-data-divider expanded" : "runway-data-divider collapsed"} onClick={toggleList}>Aircraft compatibility</h2>
        <div className={isAcsExpanded ? "ac-list" : "ac-list collapsed"}>
          { !loadingAcList ? (
              aircraftList
                .sort((a, b) => b.aircraft.mtow - a.aircraft.mtow || a.aircraft.engine.engine_model.localeCompare(b.aircraft.engine.engine_model))
                .map((ac) => {
                  const isAircraftInCards = aircraftCards?.some(card => card.aircraft_id === ac.aircraft_id);
                  return (
                  <li className="ac" key={ac.aircraft.url_code}>
                    <div onClick={() => {addAircraftToURL(ac.aircraft.url_code)}}>
                      <div className={ac.max_comp == 1 ? "green" : ac.min_comp == 1 ? "yelow" : "red"}>
                        <div className={isAircraftInCards ? "ac-selected" : ""}>
                          <div> {ac.aircraft.manufacturer.manufacturer_name}{" "} 
                                {ac.aircraft.model}-{ac.aircraft.variant} | [
                                {ac.aircraft.engine.engine_model}]
                          </div>
                        </div> 
                      </div>
                    </div>
                  </li>
                  )
                })
            ) : (
              <Oval />
            )
          }
        </div>
      </div>
    );
  }

  if (airport.airport_id) {
    return showAirport();
  }

  return null;
}

export default AirportDetail;
