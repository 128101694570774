const WORLD_RADIUS_KM = 6371; // Earth's radius in kilometers
const degToRad = (deg) => deg * (Math.PI / 180); // degrees to RAD converter
const radToDeg = (rad) => rad * (180 / Math.PI); // RAD to degrees converter


// this function is to get the distance between 2 points in kilometers
const getDistance = (lng1, lat1, lng2, lat2) => {
  const latrad1 = degToRad(lat1);
  const latrad2 = degToRad(lat2);
  const lngrad1 = degToRad(lng1);
  const lngrad2 = degToRad(lng2) - lngrad1;
  const d3 = Math.pow(Math.sin((latrad2 - latrad1) / 2), 2) +
             Math.cos(latrad1) * Math.cos(latrad2) *
             Math.pow(Math.sin(lngrad2 / 2), 2);
  return (WORLD_RADIUS_KM) * (2 * Math.atan2(Math.sqrt(d3), Math.sqrt(1 - d3)));
};

const createCirclePolygon = (center, radiusNm) => {

  const [lng, lat] = center;
  const coordinates = [];
  const radiusKm = radiusNm * 1.852;

  const distanceToNp = getDistance (lng, lat, 0, 90);
  const distanceToSp = getDistance (lng, lat, 0, -90);

  // Generate circle points
  const steps = 128; // Number of points in the circle; must use even numbers
  const angleStep = (2 * Math.PI) / steps;  

  // iterate through circle points
  for (let i = 0; i < steps; i++) {
      const angle = i * angleStep;    
      const φ1 = degToRad(lat);
      const λ1 = degToRad(lng);
      const θ = angle;
      const δ = radiusKm / WORLD_RADIUS_KM;
  
      let φ2 = Math.asin(Math.sin(φ1) * Math.cos(δ) + Math.cos(φ1) * Math.sin(δ) * Math.cos(θ));   
      const λ2 = λ1 + Math.atan2(Math.sin(θ) * Math.sin(δ) * Math.cos(φ1), Math.cos(δ) - Math.sin(φ1) * Math.sin(φ2));
  
      let newLat = radToDeg(φ2);
      let newLng = radToDeg(λ2);
  
      coordinates.push([newLng, newLat]);
    }                                           

  const southestIndex = steps/2+1

  // functions for fixing the geojson circle (the issue when drawing polygin around poles)

  function northPoleFix (coordinates) {
    // close the full circle around the earth
    coordinates.push([coordinates[0][0] - 360, coordinates[0][1]]);
    // push it to the pole
    coordinates.push([coordinates[0][0] - 360, 90]);
    // add the beginning and the end at the pole
    coordinates.push([coordinates[0][0], 90]);
    coordinates.unshift([coordinates[0][0], 90]);  
  }

  function southPoleFix (coordinates, southestIndex) {
    // close the full circle around the earth
    coordinates.splice(southestIndex, 0, [coordinates[southestIndex-1][0] - 360, coordinates[southestIndex-1][1]]);
    // push it to the pole
    coordinates.splice(southestIndex, 0, [coordinates[southestIndex-1][0] - 360, -90]);
    // // push the other to the pole
    coordinates.splice(southestIndex, 0, [coordinates[southestIndex-1][0], -90]);    
  }
      
  // northern airports    
  if (lat > 0) {
    if (distanceToNp < radiusKm) {
      northPoleFix(coordinates);
      if (distanceToSp < radiusKm) {
        southPoleFix(coordinates, southestIndex + 1);
      }
    }
    else coordinates.push(coordinates[0])
  }
  // southern airports
  if (lat < 0) {
    if (distanceToSp < radiusKm) {
      southPoleFix(coordinates, southestIndex);
      if (distanceToNp < radiusKm) {
        northPoleFix(coordinates);
      }
    }
    else coordinates.push(coordinates[0])
  }

  // Calculate boundaries
  let minX = coordinates[0][0];
  let minY = coordinates[0][1];
  let maxX = coordinates[0][0];
  let maxY = coordinates[0][1];

  coordinates.forEach(([lng, lat]) => {

    if (lng < minX) minX = lng;
    if (lat < minY) minY = lat;
    if (lng > maxX) maxX = lng;
    if (lat > maxY) maxY = lat;
  
    if (minY > 80) minY = 79.9999;
    if (minY < -80) minY = -79.9999;
    if (maxY > 80) maxY = 79.9999;
    if (maxY < -80) maxY = -79.9999;
  
    if (minX > 180) minX = 179.9999;
    if (minX < -180) minX = -179.9999;
    if (maxX > 180) maxX = 179.9999;
    if (maxX < -180) maxX = -179.9999;
  });
  

/////////////////////////////////////////////////

  return {
    type: "Feature",
    properties: {
                "minX": minX,
                "minY": minY,
                "maxX": maxX,
                "maxY": maxY,
                "autor": 'coda'
              },
    geometry: {
      type: "Polygon",
      coordinates: [coordinates]
    }
  };
};

export { getDistance, createCirclePolygon };