import React, { useState, useEffect } from "react";
import "./styles/Sidebar_r.css";
import { useSelector } from "react-redux";
import AircraftCard from "./Aircraft_card";

function AircraftCardHolder() {
  const { aircraftCards } = useSelector((state) => state.slice);
  const [sidebarr, setSidebarr] = useState(false);

  // api request for aircraft info
  useEffect(() => {
    if (aircraftCards?.length > 0) setSidebarr(true);
    else setSidebarr(false);
  }, [aircraftCards]);

  return (
    <div className={sidebarr ? "sidebar-r active" : "sidebar-r"}>
      {aircraftCards?.map((aircraft, index) => (
        <AircraftCard key={index} aircraft={aircraft}></AircraftCard>
      ))}
    </div>
  );
}

export default AircraftCardHolder;
