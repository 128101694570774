import React, { useState, useEffect } from "react";
import "./styles/Sidebar_r.css";
import { useSelector } from "react-redux";
import AirportCard from "./Aircraft_card";

function AirportCardHolder() {
  const { airports } = useSelector((state) => state.slice);
  const [sidebarr, setSidebarr] = useState(false);

  // api request for aircraft info
  useEffect(() => {
    // console.log(sidebarr, airports);
    if (airports?.length > 0) setSidebarr(true);
    else setSidebarr(false);
  }, [airports]);

  return (
    <div className={sidebarr ? "sidebar-r active" : "sidebar-r"}>
      {airports?.map((airport, index) => (
        <AirportCard key={index} airport={airport}></AirportCard>
      ))}
    </div>
  );
}

export default AirportCardHolder;
