import React from "react";
import { Helmet } from "react-helmet";
import ScWorld from "../data/world-zoom-out.png";
import ScZoom from "../data/map-zoom-in.png";
import ScAcCards from "../data/ap-detail-wt-cards.png";
import ScAc from "../data/ac-detail.png";

function Help() {
  return (
    <div className="about">
      <Helmet>
        <title>Avionia | Help</title>
      </Helmet>
      <h1>Welcome to Avionia!</h1>
      <h2>Overview</h2>
      <p>
      This online interactive tool is designed to assist aviation enthusiasts, students, 
      or professionals in exploring aircraft takeoff performance characteristics.
      Use it for airport runway length planning or just have fun simulating different scenarios.
      </p>
      <h2>How to?</h2>
      <h4>1. Map mode</h4>
      <p>
      Move the interactive map to find and select the airport.
      As you move the map or zoom in/out, you will see the ICAO codes of up to 23 largest airports on that screen (to be precise airports with longest runways).
      All of the other airports are marked with green dots. Zoom in until the ICAO code is displayed.
      </p>
      <div className="centered-image">
        <img src={ScWorld} alt="World-zoomed-out"></img>
      </div>
      <p>
      As you hover mouse over the ICAO code, the airport elevation and the length of its longest runway is displayed.
      Click on the ICAO code.
      </p>
      <div className="centered-image">
        <img src={ScZoom} alt="World-zoomed-in"></img>
      </div>
      <h4>2. Airport mode</h4>
      <p>
      Here, the airport card shows basic airport info, current outside air temperature, 
      a list of runways, and the list of some of the most popular airline aircraft (more will be added). Each of those is colored either green, yellow or red.
      </p>
      <div>
      <p>Red -- the aircraft cannot take off from this airport at all - its longest runway is just too short.</p>
      <p>Yellow -- the aircraft can potentially take off from this airport - but with limited takeoff weight and/or lower outside air temperature.</p>
      <p>Green -- the aircraft can take off from this airport - with MTOW (Maximum takeoff weight) and an outside air temperature of 50 degrees celsius.</p>
      </div>
      <p>
      <div className="centered-image">
        <img src={ScAcCards} alt="Aircraft-cards"></img>
      </div>
      Click on the aircraft to open a card where you can adjust the takeoff weight ranging from OEW (Operating Empty Weight) to MTOW. If the required takeoff runway length
      exceeds the longest runway of the airport, the card will turn red.
      </p>
      <h4>3. Aircraft takeoff calculator mode</h4>
      <p>
      By searching for specific aircraft in the searchbar or by clicking on the aircraft name on the card, you open a screen with aircraft details.
      Here you can find sliders which let you set takeoff weight, outside air temperature and takeoff runway elevation respectively.
      By moving them, the required runway length value will change.
      </p>
      <div className="centered-image">
        <img src={ScAc} alt="Aircraft-cards"></img>
      </div>
      <p>
      Note that here we only account for three factors that affect the required runway length, as these three are mostly used in Airport Planning Manuals
      issued by commercial airline aircraft manufacturers. In real life, the required runway length is affected by numerous factors including flap settings, runway slope,
      wind, whether the runway is dry or wet etc. This calculation is a mere estimate based on Airport Planning Manuals that can help give you an initial idea 
      of what the required runway length is.
      For precise calculations, Aircraft Operating Manuals or offitial software provided by manufacturers must be consulted.
      </p>
      <h2>Have fun!</h2>  
    </div>
    
  );
}

export default Help;
