import React from 'react';
import { Link } from 'react-router-dom';
import SearchBox from '../components/SearchBox';

function Nav() {
  return (
    <nav className="navbar navbar-expand-sm navbar-dark ">
      <a className="logo navbar-brand" href="/">   
      <img src={require('../data/logo.png')} alt='avionia-logo'/>   
        Avionia</a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#myNavbar" aria-controls="myNavbar" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

    <div className="collapse navbar-collapse" id="myNavbar">
      <ul className="navbar-nav ml-auto nav-fill">          
          {/* <li><Link to="/airport">Airports</Link></li> */}
          {/* <li className="nav-item"><Link className="nav-link" to="/aircraft">Aircraf</Link></li>                    */}
          <li className="nav-item"><Link className="nav-link" to="/help">Help</Link></li>
          <li className="nav-item"><Link className="nav-link" to="/about">About</Link></li>          
      </ul>
    </div>
    <SearchBox placeholder='Search airports or aircraft...'>            
    </SearchBox>
    </nav>
  );
}

export default Nav;
 