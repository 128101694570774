import React, { useState, useEffect, useRef } from "react";
// import { MapController } from 'react-map-gl';
import { Link } from "react-router-dom";
import "./styles/Search_box.css";

const SearchBox = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const [airportResults, setAirportResults] = useState([]);
  const [aircraftResults, setAircraftResults] = useState([]);

  // ovo je da se skloni search kad se klikne bilo gde drugde

  useEffect(() => {
    document.addEventListener("click", close);
    return () => document.removeEventListener("click", close);
  }, []);

  function close(e) {
    // console.log([e.target, ref.current]);
    setOpen(e && e.target === ref.current);
  }

  let pretraga = "";
  const handleChange = (e) => {
    // console.log(e.target.value)
    pretraga = e.target.value;
    fetchItems();
    // console.log(searchResults);
    // console.log(open, ref.current);
    fetchItems2();
    // console.log(searchResults2);
  };

  const fetchItems = async () => {
    const data = await fetch(`/api/search/airport/${pretraga}`);
    const items = await data.json();
    setAirportResults(items);
  };

  const fetchItems2 = async () => {
    const data = await fetch(`/api/search/aircraft/${pretraga}`);
    const items2 = await data.json();
    setAircraftResults(items2);
  };

  return (
    <div className="search">
      <input
        className="form-control"
        type="search"
        placeholder="Search airports or aircraft..."
        onChange={handleChange}
        onClick={() => setOpen((prev) => !prev)}
        ref={ref}
      />
      <div className="">
        <ul className={`search-ap ${open ? "open" : null}`}>
          {airportResults
            .sort((a, b) =>
              a.max_runway_length_m > b.max_runway_length_m ? -1 : 1
            )
            .map((item) => (
              <li className="ap" key={item.icao}>
                <Link
                  to={`/airport/${item.icao}/`}
                  className="ap"
                  // onClick={e => {
                  // props.callBackReloadAirport(item)
                  // }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <div className="head">
                    <div className="icao">{item.icao}</div>
                    <div className="name">{item.airport_name}</div>
                  </div>
                  <div className="other">
                    <div className={`${item.city ? "city" : null}`}>
                      {item.city ? item.city + "," : null}
                    </div>
                    <div
                      className={`${item.country.code_3 ? "country" : null}`}
                    >
                      {item.country.code_3 ? item.country.code_3 : null}
                    </div>
                  </div>
                </Link>
              </li>
            ))}


          {aircraftResults.map((item2) => (
            <li className="ac" key={item2.url_code}>
              <Link
                to={`/aircraft/${item2.url_code}/`}
                className="ac"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <div className="head">
                  <div className="icao">
                    {item2.manufacturer.manufacturer_name} {item2.model}-
                    {item2.variant}
                  </div>
                  {/* <div className='name'> {item2.engine.manufacturer} {item2.engine.engine_model}</div> */}
                </div>
                <div className="other">
                  <div className="icao">
                    {" "}
                    {item2.engine.manufacturer} {item2.engine.engine_model}
                  </div>
                </div>
              </Link>
            </li>
          ))}

          
        </ul>
      </div>
    </div>
  );
};

export default SearchBox;
