import React from "react";
import { Helmet } from "react-helmet";
import { ReactComponent as BMC } from "../data/bmc-button.svg";

function About() {
  return (
    <div className="about">
      <Helmet>
        <title>Avionia | About</title>
      </Helmet>
      <h1>What&apos;s this?</h1>
      <p>
        This is an interactive tool for various insights on aviation
        performance calculations.
      </p>
      <p>Currently at a very early stage and with limited time spent for development. More features yet to come.</p>
      <p>If you would like to support this work, please consider buying me a coffee ☕</p>
      <a href="https://www.buymeacoffee.com/avionia" target="_blank" rel="noopener noreferrer">
        <BMC className="bmc-logo" alt="Buy Me a Coffee"></BMC>
      </a>
      <div className="disclaimer">
        DISCLAIMER:
        This website provides aviation calculations for experimental, educational and simulation purposes. Do not rely on the calculations and data for real-life aviation or safety-critical applications.
        The calculations may be based on simplified models, assumptions, or experimental data and may not accurately reflect all variables and conditions present in actual flight scenarios. Always verify critical information through reliable sources and consult with qualified aviation professionals for accurate and up-to-date data.
        By using this website, you acknowledge that the calculations are experimental and may have limitations. The website owners, developers, and contributors are not liable for any consequences resulting from the use of the data in real-life applications.
      </div>
    </div>
  );
}

export default About;
